import Carousel from 'react-bootstrap/Carousel';
import offerThumbnil from '../../../../assets/images/banner.png'
import './common-carousel.scss'
import { useState } from 'react';

const CommonCarousel = (Props) => {
    const [bannerLink, setBannerLink] = useState('')
    const bannerClick = (data) => {
        if (data?.path_link) {
            setBannerLink(data?.path_link)
            setTimeout(() => {
                document.getElementById('bannerLink').click()
            }, 500)
        }
        console.log(data)
    }
    return (
        <div className='common-carousel'>
            <Carousel data-bs-theme="dark" indicators="false">
                {
                    Props.bannerImg.map((item) => (
                        <Carousel.Item key={item.image}>
                            <img
                                key={item.image}
                                className="d-block w-100"
                                src={item.image}
                                alt="First slide"
                                onClick={() => bannerClick(item)}
                            />

                        </Carousel.Item>

                    ))
                }

            </Carousel>
            <a href={bannerLink} target="_blank" id='bannerLink' hidden></a>
        </div>
    )
}

export default CommonCarousel